import Router from "next/router";
import { FC, useState } from "react";

import FaIcon from "@components/FaIcon";
import { IconColor, IconType } from "@components/FaIcon/FaIcon";

const ProductSearchInput: FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const onSubmit = () => {
    if (searchTerm.length >= 3) {
      Router.push(`/course/search?searchTerm=${searchTerm}`);
    }
  };

  return (
    <div className="product-search-input">
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSubmit();
          }
        }}
      />
      <button onClick={onSubmit}>
        <FaIcon type={IconType.Search} color={IconColor.Secondary} />
      </button>
      <style jsx>{`
        .product-search-input {
          display: flex;
        }

        input {
          width: 100%;
          height: 34px;
          padding: 6px 12px;
          font-size: 14px;
          line-height: 1.42857143;
          color: #555;
          border: 1px solid #ccc;
          border-radius: 4px 0 0 4px;
          outline: none;
        }

        button {
          border: 1px solid #ccc;
          border-radius: 0 4px 4px 0;
          outline: none;
          padding: 0 12px;
          cursor: pointer;
        }
      `}</style>
    </div>
  );
};

export default ProductSearchInput;
