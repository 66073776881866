import moment from "moment-timezone";

export function timezoneAbbreviationName(timezoneId: string): string {
  return moment().tz(timezoneId).format("z");
}

export function timezoneLongName(timezoneId: string): string {
  const abbr = timezoneAbbreviationName(timezoneId);
  return abbrs[abbr] || abbr;
}

const abbrs = {
  EST: "Eastern Standard Time",
  EDT: "Eastern Daylight Time",
  CST: "Central Standard Time",
  CDT: "Central Daylight Time",
  MST: "Mountain Standard Time",
  MDT: "Mountain Daylight Time",
  PST: "Pacific Standard Time",
  PDT: "Pacific Daylight Time",
};
