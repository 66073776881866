import Router from "next/router";
import { FC } from "react";

import Button from "@components/Button";
import ProductSearchInput from "@components/ProductSearchInput";

const Hero: FC = () => {
  return (
    <>
      <div className="hero">
        <div className="hero-block">
          <div className="hero-content">
            <div className="search">
              <p>What would you like to learn today?</p>
              <ProductSearchInput />
            </div>
            <div className="activation">
              <Button onClick={() => Router.push("/activate")}>Activate License</Button>
            </div>
          </div>
        </div>
      </div>
      {/* language=CSS */}
      <style jsx>
        {`
          .hero {
            height: 390px;
            overflow: hidden;
            width: 100vw;
            max-width: 100vw;
            padding-left: 0;
            padding-right: 0;
            margin-left: auto;
            margin-right: auto;
            box-sizing: border-box;
            display: block;
            left: 0;
            background-image: url("/static/genie/hero/hero-image.jpg");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
          }

          .hero-block {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .search {
            font-size: 22px;
            font-weight: 400;
            color: rgb(0, 102, 204);
            padding: 135px 0 0;
          }

          .search p {
            margin: 0 0 10px 0;
            padding: 0 30px;
          }

          .activation {
            padding: 60px 0 0 0;
            width: 60%;
            margin: 0 auto;
          }
        `}
      </style>
    </>
  );
};

export default Hero;
