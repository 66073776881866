import { getPrice } from "@api/products";
import { getSrc } from "@api/thumbnail";
import { CFC } from "@api/types/cypress";
import Product from "@api/types/product";

import BuyButton from "@components/BuyButton";
import LinkButton from "@components/LinkButton";

const ProductCourseCard: CFC<{ product: Product }> = ({ product, cy }) => {
  return (
    <>
      <div data-cy={cy} className="ProductCard">
        <img src={getSrc(product.thumbnail.src)} alt={product.title} />
        <div className="title">{product.title}</div>
        <div className="sku">{`SKU: ${product.mpn}`}</div>
        <div className="description" dangerouslySetInnerHTML={{ __html: product.description }} />
        <div className="actions">
          <div className="price">
            <span className="price-value">{getPrice(product)}</span>
          </div>
          <div className="buttons">
            {product.course && product.course.hasCoursePackages ? (
              <BuyButton product={product} />
            ) : (
              <span className="error">Course not ready</span>
            )}
            <LinkButton href={`/course/id/${encodeURIComponent(product.mpn)}`} theme="secondary">
              Course Details
            </LinkButton>
          </div>
        </div>
      </div>
      {/* language=CSS */}
      <style jsx>
        {`
          .ProductCard {
            position: relative;
            padding-bottom: 120px;
          }

          .ProductCard img {
            width: 100%;
            max-width: 100%;
          }

          .title {
            margin: 16px 0;
            font-size: 24px;
            font-weight: 500;
            color: rgb(0, 102, 204);
          }

          .sku {
            font-weight: bold;
            margin: 15px 0;
          }

          .actions {
            position: absolute;
            bottom: 0;
            width: 100%;
          }

          .buttons {
            display: flex;
          }

          .description {
            line-height: 26px;
            color: rgb(51, 51, 51);
          }

          .price {
            margin: 0 0 15px 0;
          }

          .price-value {
            color: #7e7e7e;
            font-size: 24px;
          }

          .error {
            width: 100%;
            color: rgb(255, 0, 0);
            text-align: center;
            padding: 15px 0;
          }
        `}
      </style>
    </>
  );
};

export default ProductCourseCard;
