import Product from "@api/types/product";
import { FC } from "react";

import ProductCard from "@components/ProductCard";
import ProductCourseCard from "@components/ProductCourseCard";
import ProductEventCard from "@components/ProductEventCard";
import ProductEventSetCard from "@components/ProductEventSetCard";

const ProductCardGrid: FC<{ products: Product[] }> = ({ products }) => {
  return (
    <>
      <div className="ProductCardGrid">
        {products.map((p) => {
          return p.itemType.toUpperCase() === "TDSEVENT" ? (
            p.event.category === "EVENT_SET" ? (
              <ProductEventSetCard cy="product-card" key={p.id} product={p} />
            ) : (
              <ProductEventCard cy="product-card" key={p.id} product={p} />
            )
          ) : p.itemType.toUpperCase() === "LMSTDS" ? (
            <ProductCourseCard cy="product-card" key={p.id} product={p} />
          ) : (
            <ProductCard cy="product-card" key={p.id} product={p} />
          );
        })}
      </div>
      {/* language=CSS */}
      <style jsx>
        {`
          .ProductCardGrid {
            display: grid;
            grid-gap: 40px;
          }

          @media screen and (min-width: 768px) {
            .ProductCardGrid {
              grid-template-columns: repeat(2, 1fr);
            }
          }

          @media screen and (min-width: 1024px) {
            .ProductCardGrid {
              grid-template-columns: repeat(3, 1fr);
            }
          }
        `}
      </style>
    </>
  );
};

export default ProductCardGrid;
