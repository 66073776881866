import { GEN_SITE } from "@api/constants";
import { CFC } from "@api/types/cypress";
import Product from "@api/types/product";
import { useRouter } from "next/router";
import { useContext } from "react";

import Button from "@components/Button";
import { ButtonProps } from "@components/Button/Button";
import UserContext from "@components/UserContext";

type BuyButtonProps = ButtonProps & {
  product: Product;
};

const BuyButton: CFC<BuyButtonProps> = (props) => {
  const router = useRouter();
  const { addProduct } = useContext(UserContext);
  const { product, cy } = props;

  return (
    <>
      {product.notPurchasable !== "true" ? (
        <Button
          cy={cy}
          {...props}
          onClick={async () => {
            await addProduct({
              productId: product.id,
              variantId: product.defaultVariant.id,
              quantity: 1,
              setAttribute: [{ name: "site", value: GEN_SITE }], // GEN has two sites.  This is to identify the site.
            });
            await router.push("/cart");
          }}>
          Buy Now
        </Button>
      ) : (
        <Button cy={cy} {...props} onClick={() => router.push("/activate?sku=" + product.mpn)}>
          Activate License
        </Button>
      )}
    </>
  );
};

export default BuyButton;
